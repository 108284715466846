import { useQuery } from "@tanstack/react-query";
import { DocumentApi } from "certiblok-api-manager";
import { useApi } from "../../../../../utils/hooks/api";

export const getGetQrCodeQueryKey = {
	base: ["qrCode"],
	documentId: (documentId: string) => [...getGetQrCodeQueryKey.base, { documentId }],
};

export const useGetQrCode = (documentId?: string) => {
	const documentApi = useApi(DocumentApi);

	return useQuery({
		queryKey: getGetQrCodeQueryKey.documentId(documentId ?? ""),
		queryFn: () => {
			return documentApi.getDocumentQr(documentId ?? "");
		},
		enabled: Boolean(documentId),
		select: (data) => data.data,
	});
};

export const getGetQrCodeImageQueryKey = {
	base: ["qrCodeImage"],
	documentId: (documentId: string) => [...getGetQrCodeImageQueryKey.base, { documentId }],
};

export const useGetQrCodeImage = (documentId?: string) => {
	const { data: qrCode } = useGetQrCode(documentId);

	const documentApi = useApi(DocumentApi);

	return useQuery({
		queryKey: getGetQrCodeImageQueryKey.documentId(documentId ?? ""),
		queryFn: () => {
			return documentApi.getDocumentQrImage(documentId ?? "");
		},
		enabled: Boolean(documentId) && Boolean(qrCode),
		select: (data) => data.data,
	});
};
