import { Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ModalNavigator,
	useModalNavigator,
	useModalRoute,
} from "../../../../../../utils/components/ModalNavigator/ModalNavigator";
import LoadingIconButton from "../../../../../../utils/components/buttons/LoadingIconButton";
import ResponsiveDialog from "../../../../../../utils/components/dialogs/ResponsiveDialog";
import CertiblokIcon from "../../../../../../utils/components/icons/CertiblokIcon";
import { CertiblokDocument } from "../../../../../../utils/interfaces/Document";
import { useGetQrCode } from "../../hooks/useGetQrCode";
import { useDownloadMultipleQr } from "./hooks/useChunkedDownloadQr";
import MLDialog from "../../../../../../utils/components/poppers";

export const DownloadQrModal = ModalNavigator.create(
	({ document: documentArray }: { document: CertiblokDocument | CertiblokDocument[] }) => {
		const { t } = useTranslation();

		const { visible } = useModalRoute();
		const { popModal } = useModalNavigator();

		const [selectedDownloadType, setSelectedDownloadType] = useState("image");

		const isDocumentArray = Array.isArray(documentArray);

		const { mutate: chunkedDownload, isLoading: isDownloading } = useDownloadMultipleQr({
			onMutate: () => {
				onClose();
			},
		});

		const { data: qrCode } = useGetQrCode(
			!isDocumentArray ? documentArray.id : documentArray.length === 1 ? documentArray[0].id : undefined
		);

		const onClose = () => {
			setSelectedDownloadType("image");
			popModal();
		};

		return (
			<ResponsiveDialog
				open={visible}
				onClose={onClose}
				header={
					<Typography variant="subtitle1" className="md:mb-6 pt-1">
						{t("qrCode.desiredFormatForDownload")}
					</Typography>
				}
				footer={
					<div className="md:mt-6 flex justify-between items-center w-full gap-3">
						<Button variant="outlined" color="secondary" onClick={() => onClose()} disabled={isDownloading}>
							<CertiblokIcon name="close" color="secondary" />
							<Typography>{t("global.cancel")}</Typography>
						</Button>
						<LoadingIconButton
							variant="contained"
							color="secondary"
							className="w-full max-w-[140px]"
							loading={isDownloading}
							disabled={isDownloading || selectedDownloadType === "link"}
							icon={<CertiblokIcon name="load_outline_02" color="inherit" />}
							onClick={() => {
								chunkedDownload({
									documents: isDocumentArray ? documentArray : [documentArray],
									type: selectedDownloadType as "image" | "pdf",
								});
							}}
						>
							<Typography>{t("global.download")}</Typography>
						</LoadingIconButton>
					</div>
				}
			>
				<div className="p-6 pt-0 md:p-0 md:min-w-[360px]">
					<FormControl>
						<RadioGroup
							value={selectedDownloadType}
							onChange={(e) => {
								setSelectedDownloadType(e.target.value);
								if (e.target.value === "link") {
									const qrUrl = new URL(`qr/${qrCode?.code}`, window.location.origin).toString();
									navigator.clipboard.writeText(qrUrl).then(() => {
										MLDialog.showSnackbar(t("qrCode.copiedToClipboard"), { variant: "success" });
									});
								}
							}}
						>
							<FormControlLabel
								control={<Radio color="secondary" className="[&.MuiRadio-root]:py-2" />}
								value="image"
								label={<Typography variant="body2">{t("qrCode.image")}</Typography>}
							/>
							<FormControlLabel
								control={<Radio color="secondary" className="[&.MuiRadio-root]:py-2" />}
								value="pdf"
								label={<Typography variant="body2">{t("qrCode.pdfDoc")}</Typography>}
							/>
							<FormControlLabel
								control={<Radio color="secondary" className="[&.MuiRadio-root]:py-2" />}
								value="link"
								disabled={!qrCode}
								label={<Typography variant="body2">{t("event.copyLink")}</Typography>}
							/>
						</RadioGroup>
					</FormControl>
				</div>
			</ResponsiveDialog>
		);
	}
);
