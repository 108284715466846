"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportStatusStatusEnum = exports.ImportStatusCloudProviderEnum = void 0;
exports.ImportStatusCloudProviderEnum = {
    Drive: 'drive',
    Dropbox: 'dropbox',
    Onedrive: 'onedrive'
};
exports.ImportStatusStatusEnum = {
    Scan: 'scan',
    Import: 'import',
    Success: 'success',
    Error: 'error'
};
