"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthApi = exports.AuthApiFactory = exports.AuthApiFp = exports.AuthApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AuthApi - axios parameter creator
 * @export
 */
const AuthApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Callback google auth url
         * @param {string} [code]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackGoogleAuthUrl: (code, state, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/google/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }
            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Callback microsoft auth url
         * @param {string} code
         * @param {string} state
         * @param {string} clientInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackMicrosoftAuthUrl: (code, state, clientInfo, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'code' is not null or undefined
            (0, common_1.assertParamExists)('callbackMicrosoftAuthUrl', 'code', code);
            // verify required parameter 'state' is not null or undefined
            (0, common_1.assertParamExists)('callbackMicrosoftAuthUrl', 'state', state);
            // verify required parameter 'clientInfo' is not null or undefined
            (0, common_1.assertParamExists)('callbackMicrosoftAuthUrl', 'clientInfo', clientInfo);
            const localVarPath = `/auth/microsoft/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }
            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }
            if (clientInfo !== undefined) {
                localVarQueryParameter['client_info'] = clientInfo;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Dropbox auth callback
         * @param {string} [code]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropboxAuthCallback: (code, state, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/dropbox/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }
            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Dropbox logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropboxLogout: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/dropbox/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Execute first verification
         * @param {InputExecuteFirstVerification} [inputExecuteFirstVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirstVerification: (inputExecuteFirstVerification, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/2fa/confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputExecuteFirstVerification, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Generate t f a qr code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTFAQrCode: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/2fa/generateCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get dropbox auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxAuthUrl: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/dropbox/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get google auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleAuthUrl: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/google/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get microsoft auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMicrosoftAuthUrl: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/microsoft/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Ghost mode
         * @param {InputGhostMode} [inputGhostMode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ghostMode: (inputGhostMode, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/ghost/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputGhostMode, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Google logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogout: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/google/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Microsoft logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        microsoftLogout: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/microsoft/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Reset2 f a
         * @param {string} [xTotp] totp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset2FA: (xTotp, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/2fa/reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (xTotp !== undefined && xTotp !== null) {
                localVarHeaderParameter['x-totp'] = String(xTotp);
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Verify code
         * @param {InputVerifyCode} [inputVerifyCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCode: (inputVerifyCode, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/2fa/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputVerifyCode, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AuthApiAxiosParamCreator = AuthApiAxiosParamCreator;
/**
 * AuthApi - functional programming interface
 * @export
 */
const AuthApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.AuthApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Callback google auth url
         * @param {string} [code]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackGoogleAuthUrl(code, state, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.callbackGoogleAuthUrl(code, state, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Callback microsoft auth url
         * @param {string} code
         * @param {string} state
         * @param {string} clientInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackMicrosoftAuthUrl(code, state, clientInfo, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.callbackMicrosoftAuthUrl(code, state, clientInfo, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Dropbox auth callback
         * @param {string} [code]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropboxAuthCallback(code, state, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.dropboxAuthCallback(code, state, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Dropbox logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropboxLogout(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.dropboxLogout(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Execute first verification
         * @param {InputExecuteFirstVerification} [inputExecuteFirstVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirstVerification(inputExecuteFirstVerification, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.executeFirstVerification(inputExecuteFirstVerification, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Generate t f a qr code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTFAQrCode(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generateTFAQrCode(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get dropbox auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxAuthUrl(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDropboxAuthUrl(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get google auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleAuthUrl(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getGoogleAuthUrl(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get microsoft auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMicrosoftAuthUrl(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMicrosoftAuthUrl(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Ghost mode
         * @param {InputGhostMode} [inputGhostMode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ghostMode(inputGhostMode, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.ghostMode(inputGhostMode, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Google logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogout(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.googleLogout(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Microsoft logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        microsoftLogout(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.microsoftLogout(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Reset2 f a
         * @param {string} [xTotp] totp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset2FA(xTotp, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.reset2FA(xTotp, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Verify code
         * @param {InputVerifyCode} [inputVerifyCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCode(inputVerifyCode, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.verifyCode(inputVerifyCode, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.AuthApiFp = AuthApiFp;
/**
 * AuthApi - factory interface
 * @export
 */
const AuthApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.AuthApiFp)(configuration);
    return {
        /**
         *
         * @summary Callback google auth url
         * @param {string} [code]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackGoogleAuthUrl(code, state, options) {
            return localVarFp.callbackGoogleAuthUrl(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Callback microsoft auth url
         * @param {string} code
         * @param {string} state
         * @param {string} clientInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackMicrosoftAuthUrl(code, state, clientInfo, options) {
            return localVarFp.callbackMicrosoftAuthUrl(code, state, clientInfo, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Dropbox auth callback
         * @param {string} [code]
         * @param {string} [state]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropboxAuthCallback(code, state, options) {
            return localVarFp.dropboxAuthCallback(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Dropbox logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dropboxLogout(options) {
            return localVarFp.dropboxLogout(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Execute first verification
         * @param {InputExecuteFirstVerification} [inputExecuteFirstVerification]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeFirstVerification(inputExecuteFirstVerification, options) {
            return localVarFp.executeFirstVerification(inputExecuteFirstVerification, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Generate t f a qr code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateTFAQrCode(options) {
            return localVarFp.generateTFAQrCode(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get dropbox auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDropboxAuthUrl(options) {
            return localVarFp.getDropboxAuthUrl(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get google auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoogleAuthUrl(options) {
            return localVarFp.getGoogleAuthUrl(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get microsoft auth url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMicrosoftAuthUrl(options) {
            return localVarFp.getMicrosoftAuthUrl(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Ghost mode
         * @param {InputGhostMode} [inputGhostMode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ghostMode(inputGhostMode, options) {
            return localVarFp.ghostMode(inputGhostMode, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Google logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogout(options) {
            return localVarFp.googleLogout(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Microsoft logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        microsoftLogout(options) {
            return localVarFp.microsoftLogout(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Reset2 f a
         * @param {string} [xTotp] totp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reset2FA(xTotp, options) {
            return localVarFp.reset2FA(xTotp, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Verify code
         * @param {InputVerifyCode} [inputVerifyCode]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyCode(inputVerifyCode, options) {
            return localVarFp.verifyCode(inputVerifyCode, options).then((request) => request(axios, basePath));
        },
    };
};
exports.AuthApiFactory = AuthApiFactory;
/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
class AuthApi extends base_1.BaseAPI {
    /**
     *
     * @summary Callback google auth url
     * @param {string} [code]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    callbackGoogleAuthUrl(code, state, options) {
        return (0, exports.AuthApiFp)(this.configuration).callbackGoogleAuthUrl(code, state, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Callback microsoft auth url
     * @param {string} code
     * @param {string} state
     * @param {string} clientInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    callbackMicrosoftAuthUrl(code, state, clientInfo, options) {
        return (0, exports.AuthApiFp)(this.configuration).callbackMicrosoftAuthUrl(code, state, clientInfo, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Dropbox auth callback
     * @param {string} [code]
     * @param {string} [state]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    dropboxAuthCallback(code, state, options) {
        return (0, exports.AuthApiFp)(this.configuration).dropboxAuthCallback(code, state, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Dropbox logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    dropboxLogout(options) {
        return (0, exports.AuthApiFp)(this.configuration).dropboxLogout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Execute first verification
     * @param {InputExecuteFirstVerification} [inputExecuteFirstVerification]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    executeFirstVerification(inputExecuteFirstVerification, options) {
        return (0, exports.AuthApiFp)(this.configuration).executeFirstVerification(inputExecuteFirstVerification, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Generate t f a qr code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    generateTFAQrCode(options) {
        return (0, exports.AuthApiFp)(this.configuration).generateTFAQrCode(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get dropbox auth url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    getDropboxAuthUrl(options) {
        return (0, exports.AuthApiFp)(this.configuration).getDropboxAuthUrl(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get google auth url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    getGoogleAuthUrl(options) {
        return (0, exports.AuthApiFp)(this.configuration).getGoogleAuthUrl(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get microsoft auth url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    getMicrosoftAuthUrl(options) {
        return (0, exports.AuthApiFp)(this.configuration).getMicrosoftAuthUrl(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Ghost mode
     * @param {InputGhostMode} [inputGhostMode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    ghostMode(inputGhostMode, options) {
        return (0, exports.AuthApiFp)(this.configuration).ghostMode(inputGhostMode, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Google logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    googleLogout(options) {
        return (0, exports.AuthApiFp)(this.configuration).googleLogout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Microsoft logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    microsoftLogout(options) {
        return (0, exports.AuthApiFp)(this.configuration).microsoftLogout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Reset2 f a
     * @param {string} [xTotp] totp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    reset2FA(xTotp, options) {
        return (0, exports.AuthApiFp)(this.configuration).reset2FA(xTotp, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Verify code
     * @param {InputVerifyCode} [inputVerifyCode]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    verifyCode(inputVerifyCode, options) {
        return (0, exports.AuthApiFp)(this.configuration).verifyCode(inputVerifyCode, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AuthApi = AuthApi;
