import Papa from "papaparse";
import { useEffect, useState } from "react";

const CsvViewer = ({ csvUrl, onLoad }: { csvUrl: string; onLoad: (success: boolean) => void }) => {
	const [data, setData] = useState<any[]>([]);

	useEffect(() => {
		if (!csvUrl) return;

		Papa.parse(csvUrl, {
			download: true,
			header: true, // Se il CSV ha intestazioni
			skipEmptyLines: true,
			complete: (result) => {
				setData(result.data as any);
				onLoad?.(true);
			},
			error: (error) => {
				console.error("Errore nel parsing del CSV:", error);
				onLoad?.(false);
			},
		});
	}, [csvUrl, onLoad]);

	return (
		<div className="max-w-full mx-auto p-4">
			<h2 className="text-lg font-semibold text-center mb-4 text-secondary">Visualizzatore CSV</h2>

			<div className="overflow-x-auto border border-secondary/20 rounded-lg shadow-lg">
				<table className="w-full border-collapse text-sm">
					{data.length > 0 && (
						<>
							<thead>
								<tr className="bg-secondary/10">
									{Object.keys(data[0]).map((key, index) => (
										<th key={index} className="px-4 py-3 border-b-2 border-secondary/30 text-left font-bold text-secondary">
											{key}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{data.map((row, rowIndex) => (
									<tr key={rowIndex} className="even:bg-grey/10 hover:bg-grey/40 bg-white/50 transition-colors">
										{Object.values(row).map((value: any, colIndex) => (
											<td key={colIndex} className="px-4 py-2 border-b border-secondary/20">
												{value}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</>
					)}
				</table>
			</div>

			{data.length === 0 && <p className="text-secondary/60 text-center mt-4 text-lg">Caricamento dati in corso...</p>}
		</div>
	);
};

export default CsvViewer;
