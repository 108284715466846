"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QrcodeApi = exports.QrcodeApiFactory = exports.QrcodeApiFp = exports.QrcodeApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * QrcodeApi - axios parameter creator
 * @export
 */
const QrcodeApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Download qr code pdf
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadQrCodePdf: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('downloadQrCodePdf', 'id', id);
            const localVarPath = `/document/{id}/qr/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get qr code data
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQrCodeData: (code, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'code' is not null or undefined
            (0, common_1.assertParamExists)('getQrCodeData', 'code', code);
            const localVarPath = `/document/qr/{code}/`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary View qr code
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewQrCode: (code, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'code' is not null or undefined
            (0, common_1.assertParamExists)('viewQrCode', 'code', code);
            const localVarPath = `/document/qr/{code}/view`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.QrcodeApiAxiosParamCreator = QrcodeApiAxiosParamCreator;
/**
 * QrcodeApi - functional programming interface
 * @export
 */
const QrcodeApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.QrcodeApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Download qr code pdf
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadQrCodePdf(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.downloadQrCodePdf(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get qr code data
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQrCodeData(code, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getQrCodeData(code, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary View qr code
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewQrCode(code, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.viewQrCode(code, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.QrcodeApiFp = QrcodeApiFp;
/**
 * QrcodeApi - factory interface
 * @export
 */
const QrcodeApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.QrcodeApiFp)(configuration);
    return {
        /**
         *
         * @summary Download qr code pdf
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadQrCodePdf(id, options) {
            return localVarFp.downloadQrCodePdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get qr code data
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQrCodeData(code, options) {
            return localVarFp.getQrCodeData(code, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary View qr code
         * @param {string} code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewQrCode(code, options) {
            return localVarFp.viewQrCode(code, options).then((request) => request(axios, basePath));
        },
    };
};
exports.QrcodeApiFactory = QrcodeApiFactory;
/**
 * QrcodeApi - object-oriented interface
 * @export
 * @class QrcodeApi
 * @extends {BaseAPI}
 */
class QrcodeApi extends base_1.BaseAPI {
    /**
     *
     * @summary Download qr code pdf
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QrcodeApi
     */
    downloadQrCodePdf(id, options) {
        return (0, exports.QrcodeApiFp)(this.configuration).downloadQrCodePdf(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get qr code data
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QrcodeApi
     */
    getQrCodeData(code, options) {
        return (0, exports.QrcodeApiFp)(this.configuration).getQrCodeData(code, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary View qr code
     * @param {string} code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QrcodeApi
     */
    viewQrCode(code, options) {
        return (0, exports.QrcodeApiFp)(this.configuration).viewQrCode(code, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.QrcodeApi = QrcodeApi;
