"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharenoteApi = exports.SharenoteApiFactory = exports.SharenoteApiFp = exports.SharenoteApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * SharenoteApi - axios parameter creator
 * @export
 */
const SharenoteApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get share note children
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getShareNoteChildren: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getShareNoteChildren', 'id', id);
            const localVarPath = `/sharenote/{id}/children`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get share note replies
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getShareNoteReplies: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getShareNoteReplies', 'id', id);
            const localVarPath = `/sharenote/{id}/reply`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Reply to share note
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        replyToShareNote: (uNKNOWNBASETYPE, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'uNKNOWNBASETYPE' is not null or undefined
            (0, common_1.assertParamExists)('replyToShareNote', 'uNKNOWNBASETYPE', uNKNOWNBASETYPE);
            const localVarPath = `/sharenote/reply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(uNKNOWNBASETYPE, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.SharenoteApiAxiosParamCreator = SharenoteApiAxiosParamCreator;
/**
 * SharenoteApi - functional programming interface
 * @export
 */
const SharenoteApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.SharenoteApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get share note children
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getShareNoteChildren(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getShareNoteChildren(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get share note replies
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getShareNoteReplies(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getShareNoteReplies(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Reply to share note
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        replyToShareNote(uNKNOWNBASETYPE, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.replyToShareNote(uNKNOWNBASETYPE, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.SharenoteApiFp = SharenoteApiFp;
/**
 * SharenoteApi - factory interface
 * @export
 */
const SharenoteApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.SharenoteApiFp)(configuration);
    return {
        /**
         *
         * @summary Get share note children
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getShareNoteChildren(id, filter, options) {
            return localVarFp.getShareNoteChildren(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get share note replies
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getShareNoteReplies(id, options) {
            return localVarFp.getShareNoteReplies(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Reply to share note
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        replyToShareNote(uNKNOWNBASETYPE, options) {
            return localVarFp.replyToShareNote(uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
    };
};
exports.SharenoteApiFactory = SharenoteApiFactory;
/**
 * SharenoteApi - object-oriented interface
 * @export
 * @class SharenoteApi
 * @extends {BaseAPI}
 */
class SharenoteApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get share note children
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SharenoteApi
     */
    getShareNoteChildren(id, filter, options) {
        return (0, exports.SharenoteApiFp)(this.configuration).getShareNoteChildren(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get share note replies
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SharenoteApi
     */
    getShareNoteReplies(id, options) {
        return (0, exports.SharenoteApiFp)(this.configuration).getShareNoteReplies(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Reply to share note
     * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SharenoteApi
     */
    replyToShareNote(uNKNOWNBASETYPE, options) {
        return (0, exports.SharenoteApiFp)(this.configuration).replyToShareNote(uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.SharenoteApi = SharenoteApi;
