/** @jsxImportSource @emotion/react */

import { Box, Button, Collapse, Grid, Stack, Typography } from "@mui/material";
import type { Folder } from "certiblok-api-manager";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { TransitionGroup } from "react-transition-group";
import BottomSheet from "../../../../utils/components/bottomSheet/BottomSheet";
import LoadingIconButton from "../../../../utils/components/buttons/LoadingIconButton";
import CertiblokTextInput from "../../../../utils/components/CertiblokTextInput";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import Loading from "../../../../utils/components/Loading";
import { ModalNavigator } from "../../../../utils/components/ModalNavigator/ModalNavigator";
import WhitePopperTooltip from "../../../../utils/components/poppers/WhitePopperTooltip";
import WhiteModal from "../../../../utils/components/WhiteModal";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import { useMe } from "../../../../utils/hooks/useMe";
import { useCompany } from "../../../../utils/queries/globalQueries";
import { queryClient } from "../../../../utils/reactQueryClient";
import { AvailableFolderColors } from "../../constants/AvailableFolderColors";
import FolderOrAuditRoomNamePicker from "../FolderNamePicker";
import AlreadyInLockedFolderBanner from "./components/AlreadyInLockedFolderBanner";
import EditCreateFolderLockedSwitch from "./components/EditCreateFolderLockedSwitch";
import MemberInFolder from "./components/MemberInFolder";
import TeamInFolder from "./components/TeamInFolder";
import TeamMembersGuestsGroupsAutocomplete from "./components/TeamMembersGuestsGroupsAutocomplete";
import { getUseEditCreateFolderFormKey, useEditCreateFolderForm } from "./hooks/useEditCreateFolderForm";
import { useModalManagement } from "./hooks/useModalManagement";
import { useEditCreateEditRoomFolder, useEditCreateFolder } from "./queries/editCreateFolderModalQueries";

export type EditCreateFolderModalProps = {
	onSuccess?: (folder: Folder) => void;
	isInEditRoom?: boolean;
} & (
	| { isEdit: true; folder: Folder; parentFolderId?: never }
	| { isEdit?: false; parentFolderId?: string; folder?: never }
);

const EditCreateFolderModal = ModalNavigator.create<EditCreateFolderModalProps>(
	({ isEdit, onSuccess, folder, parentFolderId, isInEditRoom }) => {
		const { me } = useMe();
		const { t } = useTranslation();
		const location = useLocation();
		const isMd = useBetterMediaQuery("md");
		const { bottomSheetProps, popBottomSheet, visible } = useModalManagement();

		const isEditRoom = location?.pathname?.includes("/edit_rooms/");

		const { data: companyData, isLoading: isCompanyLoading } = useCompany();

		const {
			folderName,
			setFolderName,
			folderColor,
			setFolderColor,
			lockFolderEnabled,
			setLockFolderEnabled,
			reservedToUsers,
			setReservedToUsers,
			reservedToGroups,
			setReservedToGroups,
			filteredReservedToUserIds,
			setFilteredReservedToUserIds,
			filteredReservedToGroupsIds,
			setFilteredReservedToGroupsIds,
			isParentRestricted,
			parentFolder,
			isLoading,
		} = useEditCreateFolderForm({ folder: folder, parentFolderId: parentFolderId, isEditRoom: isInEditRoom });

		const editCreateEditRoomFolder = useEditCreateEditRoomFolder();
		const editCreateFolder = useEditCreateFolder();
		const isLoadingSubmit = editCreateFolder.isLoading || editCreateEditRoomFolder.isLoading;

		const onSubmit = () => {
			if (me) {
				let mutation;
				const reservedToUserIds =
					lockFolderEnabled || isParentRestricted ? [...reservedToUsers.map((user) => user.id), me.id] : [];

				if (isEdit && isEditRoom) {
					mutation = editCreateEditRoomFolder.mutateAsync({
						isEdit: true,
						folderId: folder.id,
						folderName,
						folderColor,
						reservedToUserIds: reservedToUserIds,
						reservedToTeamIds: reservedToGroups.map((group) => group.id),
					});
				} else if (isEdit) {
					mutation = editCreateFolder.mutateAsync({
						isEdit: true,
						folderId: folder.id,
						folderName,
						folderColor,
						reservedToUserIds: reservedToUserIds,
						reservedToTeamIds: reservedToGroups.map((group) => group.id),
					});
				} else if (isEditRoom) {
					mutation = editCreateEditRoomFolder.mutateAsync({
						isEdit: false,
						folderColor,
						folderName,
						parentId: parentFolderId,
						reservedToUserIds,
						reservedToTeamIds: reservedToGroups.map((group) => group.id),
					});
				} else {
					mutation = editCreateFolder.mutateAsync({
						isEdit: false,
						folderColor,
						folderName,
						parentId: parentFolderId,
						reservedToUserIds,
						reservedToTeamIds: reservedToGroups.map((group) => group.id),
					});
				}
				mutation.then((res) => {
					onSuccess?.(res.data);
					onClose();
				});
			}
		};

		const onClose = () => {
			if (isLoadingSubmit) return;
			queryClient.resetQueries({ queryKey: getUseEditCreateFolderFormKey(folder?.id) });
			popBottomSheet();
			setFolderName("");
			setFolderColor(AvailableFolderColors[0]);
			setLockFolderEnabled(false);
			setFilteredReservedToUserIds(undefined);
			setFilteredReservedToGroupsIds(undefined);
		};

		const header = (
			<Typography
				children={isEdit ? t("folders.handleFolder") : t("folders.createFolder")}
				variant="subtitle1"
				sx={{ paddingBottom: 3 }}
			/>
		);

		const children = (
			<>
				{isLoading || isCompanyLoading ? (
					<div className="flex-grow flex items-center justify-center overflow-visible min-h-[62px]">
						<Loading title={t("folders.folderLoading")} />
					</div>
				) : (
					<Fragment>
						<FolderOrAuditRoomNamePicker
							folderName={folderName}
							updateFolderName={(value: string) => {
								setFolderName(value);
							}}
							selectedColor={folderColor}
							updateColor={(value: string) => {
								setFolderColor(value);
							}}
							ColorPickerProps={{
								className: "flex-wrap md:flex-nowrap",
							}}
							textInputProps={{
								label: t("folders.folderName"),
								placeholder: t("folders.folderName"),
								onBlur: () => {},
								size: "medium",
								sx: {
									backgroundColor: "backgroundSecondary.default",
								},
							}}
						/>
						{!isParentRestricted && (
							<Stack direction="row" alignItems={"center"} gap={4} sx={{ paddingTop: 8 }}>
								<Box>
									<Typography children={t("folders.keepFolderPrivate")} variant="subtitle2" />
									<Typography children={t("folders.limitingFolderWarning")} variant="caption" sx={{ color: "#000000" }} />
								</Box>
								<EditCreateFolderLockedSwitch
									checked={lockFolderEnabled}
									onChange={(e, checked) => setLockFolderEnabled(checked)}
									color="secondary"
									icon="lock_bold_01"
								/>
							</Stack>
						)}
						{isParentRestricted && <AlreadyInLockedFolderBanner sx={{ marginTop: 8 }} />}
						<Collapse in={isParentRestricted || lockFolderEnabled}>
							<Box>
								{isParentRestricted ? (
									<Typography
										children={t("folders.chooseWhoToGiveAccess")}
										variant="caption"
										component="p"
										sx={{ color: "#0000008A", pt: 8, pb: 1 }}
									/>
								) : (
									<Stack direction="row" alignItems={"center"} gap={2} sx={{ pt: 10, pb: 2 }}>
										<Typography
											children={t("folders.chooseWhoCanAccess")}
											variant="caption"
											component="p"
											sx={{ color: "#0000008A" }}
										/>
										<WhitePopperTooltip enabled title={t("folders.lockingFolderDescription")}>
											<CertiblokIcon size={16} color="#0000008A" name={"comunication_outline_02"} />
										</WhitePopperTooltip>
									</Stack>
								)}
								{!isParentRestricted ? (
									// <CompanyMemberAutocomplete
									// 	onSelectUser={(user) => {
									// 		setReservedToUsers((prev) => [...prev, user]);
									// 	}}
									// 	size="small"
									// />
									<TeamMembersGuestsGroupsAutocomplete
										disabledUsersIds={reservedToUsers.map((user) => user.id)}
										onSelectUser={(user) => {
											setReservedToUsers((prev) => [...prev, user]);
										}}
										disabledGroupsIds={reservedToGroups.map((group) => group.id)}
										onSelectGroup={(group) => {
											setReservedToGroups((prev) => [...prev, group]);
										}}
										includeGroups
									/>
								) : (
									<CertiblokTextInput
										fullWidth
										placeholder={t("teamGroups.searchMembers")}
										onChange={(ev) => {
											const value = ev.target.value;
											if (value.length > 0) {
												setFilteredReservedToUserIds(
													(parentFolder?.reservedToUsers ?? [])
														.filter((user) =>
															`${user.name}${user.surname}`.toLowerCase().includes(value.toLowerCase().replaceAll(" ", ""))
														)
														.map((user) => user.id)
												);
												setFilteredReservedToGroupsIds(
													(parentFolder?.reservedToTeams ?? [])
														.filter((team) => team.name.toLowerCase().includes(value.toLowerCase()))
														.map((team) => team.id)
												);
											} else {
												setFilteredReservedToUserIds((parentFolder?.reservedToUsers ?? []).map((user) => user.id));
												setFilteredReservedToGroupsIds((parentFolder?.reservedToTeams ?? []).map((team) => team.id));
											}
										}}
										color="secondary"
										sx={{ backgroundColor: (theme) => theme.palette.backgroundSecondary.default }}
									/>
								)}
								{!isParentRestricted && (
									<Typography
										children={t("folders.teamMembersAlreadyInFolder")}
										variant="caption"
										component="p"
										sx={{ pt: 4, pb: 2, color: "#0000008A" }}
									/>
								)}

								<TransitionGroup
									css={{ maxHeight: "20vh", overflow: "auto", marginTop: isParentRestricted ? "16px" : "unset" }}
								>
									<MemberInFolder user={companyData?.data.owner} isAdmin />
									{(isParentRestricted
										? (parentFolder?.reservedToTeams ?? []).filter(
												(team) => !filteredReservedToGroupsIds || filteredReservedToGroupsIds.includes(team.id)
										  )
										: reservedToGroups
									).map((team) => {
										const isExcludedFromParent = isParentRestricted && reservedToGroups.findIndex((t) => t.id === team.id) === -1;
										return (
											<Collapse key={team?.id}>
												<TeamInFolder
													team={team}
													endIcon={isParentRestricted ? "eye" : "delete"}
													onEndIconClick={() => {
														if (!isExcludedFromParent || !isParentRestricted) {
															setReservedToGroups((prev) => prev.filter((selectedGroup) => selectedGroup.id !== team.id));
														} else {
															setReservedToGroups((prev) => [...prev, team]);
														}
													}}
													sx={{
														marginTop: 1,
													}}
													excludedFromParent={isExcludedFromParent}
												/>
											</Collapse>
										);
									})}
									{(isParentRestricted
										? (parentFolder?.reservedToUsers ?? []).filter(
												(user) => !filteredReservedToUserIds || filteredReservedToUserIds.includes(user.id)
										  )
										: reservedToUsers
									).map((user) => {
										const isExcludedFromParent = isParentRestricted && reservedToUsers.findIndex((u) => u.id === user.id) === -1;
										return user._id === me?.id || user._id === companyData?.data.ownerId ? null : (
											<Collapse key={user._id}>
												<MemberInFolder
													user={user}
													endIcon={isParentRestricted ? "eye" : "delete"}
													onEndIconClick={() => {
														if (!isExcludedFromParent || !isParentRestricted) {
															setReservedToUsers((prev) => prev.filter((selectedUsers) => selectedUsers.id !== user.id));
														} else {
															setReservedToUsers((prev) => [...prev, user]);
														}
													}}
													sx={{
														marginTop: 1,
													}}
													excludedFromParent={isExcludedFromParent}
												/>
											</Collapse>
										);
									})}
								</TransitionGroup>
							</Box>
						</Collapse>
					</Fragment>
				)}
			</>
		);

		const footer = (
			<Grid container justifyContent="space-between" spacing={1} sx={{ width: "100%", paddingTop: 9 }}>
				<Grid item xs="auto">
					<Button
						disabled={isLoadingSubmit}
						color="secondary"
						variant="outlined"
						size="medium"
						sx={{ gap: 2 }}
						onClick={onClose}
					>
						<CertiblokIcon size={24} color="inherit" name={"close"} />
						{t("global.cancel")}
					</Button>
				</Grid>

				<Grid item xs="auto">
					<LoadingIconButton
						size="medium"
						variant="contained"
						color="secondary"
						icon={<CertiblokIcon size={24} color="inherit" name={"success"} />}
						loading={isLoadingSubmit}
						sx={{ gap: 2 }}
						onClick={onSubmit}
						disabled={folderName === "" || isLoadingSubmit}
					>
						{isEdit ? t("global.saveEdits") : t("folders.createFolder")}
					</LoadingIconButton>
				</Grid>
			</Grid>
		);

		return isMd ? (
			<WhiteModal
				open={visible}
				onClose={onClose}
				keepMounted={false}
				containerSx={{
					borderRadius: 6,
					maxWidth: 720,
					width: "90vw",
				}}
				responsive
			>
				<Stack sx={{ height: "100%", minHeight: 280 }}>
					{header}
					{children}
					{footer}
				</Stack>
			</WhiteModal>
		) : (
			<BottomSheet {...bottomSheetProps} open={visible} onClose={onClose} header={header} footer={footer}>
				<div className="px-5 min-h-[420px]">{children}</div>
			</BottomSheet>
		);
	}
);
export default EditCreateFolderModal;
