"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FolderApi = exports.FolderApiFactory = exports.FolderApiFp = exports.FolderApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * FolderApi - axios parameter creator
 * @export
 */
const FolderApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create folder
         * @param {InputCreateFolder} [inputCreateFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder: (inputCreateFolder, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/folder/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputCreateFolder, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('deleteFolder', 'id', id);
            const localVarPath = `/folder/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Edit folder
         * @param {string} id
         * @param {InputEditFolder} [inputEditFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFolder: (id, inputEditFolder, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('editFolder', 'id', id);
            const localVarPath = `/folder/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputEditFolder, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get ancestors of folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsOfFolder: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getAncestorsOfFolder', 'id', id);
            const localVarPath = `/folder/{id}/ancestors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get folder tree view
         * @param {string} id
         * @param {number} [limit]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderTreeView: (id, limit, page, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getFolderTreeView', 'id', id);
            const localVarPath = `/folder/{id}/tree/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get folders
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [parentFolderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolders: (filter, parentFolderId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/folder/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            if (parentFolderId !== undefined) {
                localVarQueryParameter['parentFolderId'] = parentFolderId;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Folder\"]
         * @summary Get folders last changes since | Permission required: [\"readAll\", \"Folder\"]
         * @param {string} since
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoldersLastChangesSince: (since, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'since' is not null or undefined
            (0, common_1.assertParamExists)('getFoldersLastChangesSince', 'since', since);
            const localVarPath = `/folder/getLastChangesSince`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (since !== undefined) {
                localVarQueryParameter['since'] = since;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get plain folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlainFolder: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getPlainFolder', 'id', id);
            const localVarPath = `/folder/{id}/plain`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get total folders count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalFoldersCount: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/folder/countTotalFolders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Read documents of folder
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDocumentsOfFolder: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readDocumentsOfFolder', 'id', id);
            const localVarPath = `/folder/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *  **Permission required**: [\"readAll\", \"Folder\"]
         * @summary Read folder from parent | Permission required: [\"readAll\", \"Folder\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readFolderFromParent: (id, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readFolderFromParent', 'id', id);
            const localVarPath = `/folder/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.FolderApiAxiosParamCreator = FolderApiAxiosParamCreator;
/**
 * FolderApi - functional programming interface
 * @export
 */
const FolderApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.FolderApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create folder
         * @param {InputCreateFolder} [inputCreateFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(inputCreateFolder, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createFolder(inputCreateFolder, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteFolder(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Edit folder
         * @param {string} id
         * @param {InputEditFolder} [inputEditFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFolder(id, inputEditFolder, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editFolder(id, inputEditFolder, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get ancestors of folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsOfFolder(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAncestorsOfFolder(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get folder tree view
         * @param {string} id
         * @param {number} [limit]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderTreeView(id, limit, page, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFolderTreeView(id, limit, page, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get folders
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [parentFolderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolders(filter, parentFolderId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFolders(filter, parentFolderId, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Folder\"]
         * @summary Get folders last changes since | Permission required: [\"readAll\", \"Folder\"]
         * @param {string} since
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoldersLastChangesSince(since, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFoldersLastChangesSince(since, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get plain folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlainFolder(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getPlainFolder(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get total folders count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalFoldersCount(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTotalFoldersCount(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Read documents of folder
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDocumentsOfFolder(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readDocumentsOfFolder(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *  **Permission required**: [\"readAll\", \"Folder\"]
         * @summary Read folder from parent | Permission required: [\"readAll\", \"Folder\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readFolderFromParent(id, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readFolderFromParent(id, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.FolderApiFp = FolderApiFp;
/**
 * FolderApi - factory interface
 * @export
 */
const FolderApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.FolderApiFp)(configuration);
    return {
        /**
         *
         * @summary Create folder
         * @param {InputCreateFolder} [inputCreateFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFolder(inputCreateFolder, options) {
            return localVarFp.createFolder(inputCreateFolder, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(id, options) {
            return localVarFp.deleteFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Edit folder
         * @param {string} id
         * @param {InputEditFolder} [inputEditFolder]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFolder(id, inputEditFolder, options) {
            return localVarFp.editFolder(id, inputEditFolder, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get ancestors of folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAncestorsOfFolder(id, options) {
            return localVarFp.getAncestorsOfFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get folder tree view
         * @param {string} id
         * @param {number} [limit]
         * @param {number} [page]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderTreeView(id, limit, page, options) {
            return localVarFp.getFolderTreeView(id, limit, page, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get folders
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {string} [parentFolderId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolders(filter, parentFolderId, options) {
            return localVarFp.getFolders(filter, parentFolderId, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Folder\"]
         * @summary Get folders last changes since | Permission required: [\"readAll\", \"Folder\"]
         * @param {string} since
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFoldersLastChangesSince(since, options) {
            return localVarFp.getFoldersLastChangesSince(since, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get plain folder
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPlainFolder(id, options) {
            return localVarFp.getPlainFolder(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get total folders count
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalFoldersCount(options) {
            return localVarFp.getTotalFoldersCount(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Read documents of folder
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readDocumentsOfFolder(id, filter, options) {
            return localVarFp.readDocumentsOfFolder(id, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *  **Permission required**: [\"readAll\", \"Folder\"]
         * @summary Read folder from parent | Permission required: [\"readAll\", \"Folder\"]
         * @param {string} id
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readFolderFromParent(id, filter, options) {
            return localVarFp.readFolderFromParent(id, filter, options).then((request) => request(axios, basePath));
        },
    };
};
exports.FolderApiFactory = FolderApiFactory;
/**
 * FolderApi - object-oriented interface
 * @export
 * @class FolderApi
 * @extends {BaseAPI}
 */
class FolderApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create folder
     * @param {InputCreateFolder} [inputCreateFolder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    createFolder(inputCreateFolder, options) {
        return (0, exports.FolderApiFp)(this.configuration).createFolder(inputCreateFolder, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete folder
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    deleteFolder(id, options) {
        return (0, exports.FolderApiFp)(this.configuration).deleteFolder(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Edit folder
     * @param {string} id
     * @param {InputEditFolder} [inputEditFolder]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    editFolder(id, inputEditFolder, options) {
        return (0, exports.FolderApiFp)(this.configuration).editFolder(id, inputEditFolder, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get ancestors of folder
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    getAncestorsOfFolder(id, options) {
        return (0, exports.FolderApiFp)(this.configuration).getAncestorsOfFolder(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get folder tree view
     * @param {string} id
     * @param {number} [limit]
     * @param {number} [page]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    getFolderTreeView(id, limit, page, options) {
        return (0, exports.FolderApiFp)(this.configuration).getFolderTreeView(id, limit, page, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get folders
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {string} [parentFolderId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    getFolders(filter, parentFolderId, options) {
        return (0, exports.FolderApiFp)(this.configuration).getFolders(filter, parentFolderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Folder\"]
     * @summary Get folders last changes since | Permission required: [\"readAll\", \"Folder\"]
     * @param {string} since
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    getFoldersLastChangesSince(since, options) {
        return (0, exports.FolderApiFp)(this.configuration).getFoldersLastChangesSince(since, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get plain folder
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    getPlainFolder(id, options) {
        return (0, exports.FolderApiFp)(this.configuration).getPlainFolder(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get total folders count
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    getTotalFoldersCount(options) {
        return (0, exports.FolderApiFp)(this.configuration).getTotalFoldersCount(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Read documents of folder
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    readDocumentsOfFolder(id, filter, options) {
        return (0, exports.FolderApiFp)(this.configuration).readDocumentsOfFolder(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *  **Permission required**: [\"readAll\", \"Folder\"]
     * @summary Read folder from parent | Permission required: [\"readAll\", \"Folder\"]
     * @param {string} id
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    readFolderFromParent(id, filter, options) {
        return (0, exports.FolderApiFp)(this.configuration).readFolderFromParent(id, filter, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.FolderApi = FolderApi;
