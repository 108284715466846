"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenApi = exports.TokenApiFactory = exports.TokenApiFp = exports.TokenApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * TokenApi - axios parameter creator
 * @export
 */
const TokenApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/user/token/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.TokenApiAxiosParamCreator = TokenApiAxiosParamCreator;
/**
 * TokenApi - functional programming interface
 * @export
 */
const TokenApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.TokenApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.refreshToken(options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.TokenApiFp = TokenApiFp;
/**
 * TokenApi - factory interface
 * @export
 */
const TokenApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.TokenApiFp)(configuration);
    return {
        /**
         *
         * @summary Refresh token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshToken(options) {
            return localVarFp.refreshToken(options).then((request) => request(axios, basePath));
        },
    };
};
exports.TokenApiFactory = TokenApiFactory;
/**
 * TokenApi - object-oriented interface
 * @export
 * @class TokenApi
 * @extends {BaseAPI}
 */
class TokenApi extends base_1.BaseAPI {
    /**
     *
     * @summary Refresh token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenApi
     */
    refreshToken(options) {
        return (0, exports.TokenApiFp)(this.configuration).refreshToken(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.TokenApi = TokenApi;
