"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentRequestApi = exports.DocumentRequestApiFactory = exports.DocumentRequestApiFp = exports.DocumentRequestApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * DocumentRequestApi - axios parameter creator
 * @export
 */
const DocumentRequestApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Delete request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRequest: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('deleteRequest', 'id', id);
            const localVarPath = `/request/{id}/delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get request with token
         * @param {string} id
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestWithToken: (id, token, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getRequestWithToken', 'id', id);
            const localVarPath = `/request/{id}/request-with-token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get single request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleRequest: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('getSingleRequest', 'id', id);
            const localVarPath = `/request/{id}/single`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary List document requests
         * @param {'sent' | 'received'} [type]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentRequests: (type, filter, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/request/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }
            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Read request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRequest: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('readRequest', 'id', id);
            const localVarPath = `/request/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send documents for request with token
         * @param {string} id
         * @param {Array<any>} files
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sendDocumentsForRequestWithToken: (id, files, token, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('sendDocumentsForRequestWithToken', 'id', id);
            // verify required parameter 'files' is not null or undefined
            (0, common_1.assertParamExists)('sendDocumentsForRequestWithToken', 'files', files);
            const localVarPath = `/request/{id}/send-documents-with-token`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element);
                });
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Send documents for request with token limitless
         * @param {string} id
         * @param {string} [token]
         * @param {InputSendDocumentsForRequestWithTokenLimitless} [inputSendDocumentsForRequestWithTokenLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDocumentsForRequestWithTokenLimitless: (id, token, inputSendDocumentsForRequestWithTokenLimitless, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            (0, common_1.assertParamExists)('sendDocumentsForRequestWithTokenLimitless', 'id', id);
            const localVarPath = `/request/{id}/send-documents-with-token-limitless`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputSendDocumentsForRequestWithTokenLimitless, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DocumentRequestApiAxiosParamCreator = DocumentRequestApiAxiosParamCreator;
/**
 * DocumentRequestApi - functional programming interface
 * @export
 */
const DocumentRequestApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.DocumentRequestApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Delete request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRequest(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteRequest(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get request with token
         * @param {string} id
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestWithToken(id, token, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRequestWithToken(id, token, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get single request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleRequest(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSingleRequest(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary List document requests
         * @param {'sent' | 'received'} [type]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentRequests(type, filter, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listDocumentRequests(type, filter, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Read request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRequest(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readRequest(id, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Send documents for request with token
         * @param {string} id
         * @param {Array<any>} files
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sendDocumentsForRequestWithToken(id, files, token, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendDocumentsForRequestWithToken(id, files, token, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Send documents for request with token limitless
         * @param {string} id
         * @param {string} [token]
         * @param {InputSendDocumentsForRequestWithTokenLimitless} [inputSendDocumentsForRequestWithTokenLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDocumentsForRequestWithTokenLimitless(id, token, inputSendDocumentsForRequestWithTokenLimitless, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.sendDocumentsForRequestWithTokenLimitless(id, token, inputSendDocumentsForRequestWithTokenLimitless, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.DocumentRequestApiFp = DocumentRequestApiFp;
/**
 * DocumentRequestApi - factory interface
 * @export
 */
const DocumentRequestApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.DocumentRequestApiFp)(configuration);
    return {
        /**
         *
         * @summary Delete request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRequest(id, options) {
            return localVarFp.deleteRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get request with token
         * @param {string} id
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestWithToken(id, token, options) {
            return localVarFp.getRequestWithToken(id, token, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get single request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSingleRequest(id, options) {
            return localVarFp.getSingleRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary List document requests
         * @param {'sent' | 'received'} [type]
         * @param {string} [filter] page, perPage, order, orderBy, filter (object)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocumentRequests(type, filter, options) {
            return localVarFp.listDocumentRequests(type, filter, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Read request
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readRequest(id, options) {
            return localVarFp.readRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send documents for request with token
         * @param {string} id
         * @param {Array<any>} files
         * @param {string} [token]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sendDocumentsForRequestWithToken(id, files, token, options) {
            return localVarFp.sendDocumentsForRequestWithToken(id, files, token, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Send documents for request with token limitless
         * @param {string} id
         * @param {string} [token]
         * @param {InputSendDocumentsForRequestWithTokenLimitless} [inputSendDocumentsForRequestWithTokenLimitless]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendDocumentsForRequestWithTokenLimitless(id, token, inputSendDocumentsForRequestWithTokenLimitless, options) {
            return localVarFp.sendDocumentsForRequestWithTokenLimitless(id, token, inputSendDocumentsForRequestWithTokenLimitless, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DocumentRequestApiFactory = DocumentRequestApiFactory;
/**
 * DocumentRequestApi - object-oriented interface
 * @export
 * @class DocumentRequestApi
 * @extends {BaseAPI}
 */
class DocumentRequestApi extends base_1.BaseAPI {
    /**
     *
     * @summary Delete request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentRequestApi
     */
    deleteRequest(id, options) {
        return (0, exports.DocumentRequestApiFp)(this.configuration).deleteRequest(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get request with token
     * @param {string} id
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentRequestApi
     */
    getRequestWithToken(id, token, options) {
        return (0, exports.DocumentRequestApiFp)(this.configuration).getRequestWithToken(id, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get single request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentRequestApi
     */
    getSingleRequest(id, options) {
        return (0, exports.DocumentRequestApiFp)(this.configuration).getSingleRequest(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary List document requests
     * @param {'sent' | 'received'} [type]
     * @param {string} [filter] page, perPage, order, orderBy, filter (object)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentRequestApi
     */
    listDocumentRequests(type, filter, options) {
        return (0, exports.DocumentRequestApiFp)(this.configuration).listDocumentRequests(type, filter, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Read request
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentRequestApi
     */
    readRequest(id, options) {
        return (0, exports.DocumentRequestApiFp)(this.configuration).readRequest(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send documents for request with token
     * @param {string} id
     * @param {Array<any>} files
     * @param {string} [token]
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DocumentRequestApi
     */
    sendDocumentsForRequestWithToken(id, files, token, options) {
        return (0, exports.DocumentRequestApiFp)(this.configuration).sendDocumentsForRequestWithToken(id, files, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Send documents for request with token limitless
     * @param {string} id
     * @param {string} [token]
     * @param {InputSendDocumentsForRequestWithTokenLimitless} [inputSendDocumentsForRequestWithTokenLimitless]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentRequestApi
     */
    sendDocumentsForRequestWithTokenLimitless(id, token, inputSendDocumentsForRequestWithTokenLimitless, options) {
        return (0, exports.DocumentRequestApiFp)(this.configuration).sendDocumentsForRequestWithTokenLimitless(id, token, inputSendDocumentsForRequestWithTokenLimitless, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DocumentRequestApi = DocumentRequestApi;
