"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentmanagerApi = exports.DocumentmanagerApiFactory = exports.DocumentmanagerApiFp = exports.DocumentmanagerApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * DocumentmanagerApi - axios parameter creator
 * @export
 */
const DocumentmanagerApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get upload signed url audit
         * @param {InputGetUploadSignedUrlAudit} [inputGetUploadSignedUrlAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrlAudit: (inputGetUploadSignedUrlAudit, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document-manager/upload-signed-url/audit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputGetUploadSignedUrlAudit, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get upload signed url document
         * @param {InputGetUploadSignedUrlDocument} [inputGetUploadSignedUrlDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrlDocument: (inputGetUploadSignedUrlDocument, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/document-manager/upload-signed-url/document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputGetUploadSignedUrlDocument, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.DocumentmanagerApiAxiosParamCreator = DocumentmanagerApiAxiosParamCreator;
/**
 * DocumentmanagerApi - functional programming interface
 * @export
 */
const DocumentmanagerApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.DocumentmanagerApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get upload signed url audit
         * @param {InputGetUploadSignedUrlAudit} [inputGetUploadSignedUrlAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrlAudit(inputGetUploadSignedUrlAudit, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUploadSignedUrlAudit(inputGetUploadSignedUrlAudit, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get upload signed url document
         * @param {InputGetUploadSignedUrlDocument} [inputGetUploadSignedUrlDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrlDocument(inputGetUploadSignedUrlDocument, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUploadSignedUrlDocument(inputGetUploadSignedUrlDocument, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.DocumentmanagerApiFp = DocumentmanagerApiFp;
/**
 * DocumentmanagerApi - factory interface
 * @export
 */
const DocumentmanagerApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.DocumentmanagerApiFp)(configuration);
    return {
        /**
         *
         * @summary Get upload signed url audit
         * @param {InputGetUploadSignedUrlAudit} [inputGetUploadSignedUrlAudit]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrlAudit(inputGetUploadSignedUrlAudit, options) {
            return localVarFp.getUploadSignedUrlAudit(inputGetUploadSignedUrlAudit, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get upload signed url document
         * @param {InputGetUploadSignedUrlDocument} [inputGetUploadSignedUrlDocument]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrlDocument(inputGetUploadSignedUrlDocument, options) {
            return localVarFp.getUploadSignedUrlDocument(inputGetUploadSignedUrlDocument, options).then((request) => request(axios, basePath));
        },
    };
};
exports.DocumentmanagerApiFactory = DocumentmanagerApiFactory;
/**
 * DocumentmanagerApi - object-oriented interface
 * @export
 * @class DocumentmanagerApi
 * @extends {BaseAPI}
 */
class DocumentmanagerApi extends base_1.BaseAPI {
    /**
     *
     * @summary Get upload signed url audit
     * @param {InputGetUploadSignedUrlAudit} [inputGetUploadSignedUrlAudit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentmanagerApi
     */
    getUploadSignedUrlAudit(inputGetUploadSignedUrlAudit, options) {
        return (0, exports.DocumentmanagerApiFp)(this.configuration).getUploadSignedUrlAudit(inputGetUploadSignedUrlAudit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get upload signed url document
     * @param {InputGetUploadSignedUrlDocument} [inputGetUploadSignedUrlDocument]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentmanagerApi
     */
    getUploadSignedUrlDocument(inputGetUploadSignedUrlDocument, options) {
        return (0, exports.DocumentmanagerApiFp)(this.configuration).getUploadSignedUrlDocument(inputGetUploadSignedUrlDocument, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.DocumentmanagerApi = DocumentmanagerApi;
