"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Certiblok Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.8.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminUserApi = exports.AdminUserApiFactory = exports.AdminUserApiFp = exports.AdminUserApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("../common");
// @ts-ignore
const base_1 = require("../base");
/**
 * AdminUserApi - axios parameter creator
 * @export
 */
const AdminUserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Generate payment link for user
         * @param {string} planId
         * @param {'month' | 'year'} recurrence
         * @param {string} apiToken
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePaymentLinkForUser: (planId, recurrence, apiToken, userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'planId' is not null or undefined
            (0, common_1.assertParamExists)('generatePaymentLinkForUser', 'planId', planId);
            // verify required parameter 'recurrence' is not null or undefined
            (0, common_1.assertParamExists)('generatePaymentLinkForUser', 'recurrence', recurrence);
            // verify required parameter 'apiToken' is not null or undefined
            (0, common_1.assertParamExists)('generatePaymentLinkForUser', 'apiToken', apiToken);
            // verify required parameter 'userId' is not null or undefined
            (0, common_1.assertParamExists)('generatePaymentLinkForUser', 'userId', userId);
            const localVarPath = `/admin/user/{userId}/link`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            if (planId !== undefined) {
                localVarQueryParameter['planId'] = planId;
            }
            if (recurrence !== undefined) {
                localVarQueryParameter['recurrence'] = recurrence;
            }
            if (apiToken !== undefined && apiToken !== null) {
                localVarHeaderParameter['api-token'] = String(apiToken);
            }
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Invite user
         * @param {InputInviteUser} [inputInviteUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser: (inputInviteUser, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/admin/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication headerAuth required
            yield (0, common_1.setApiKeyToObject)(localVarHeaderParameter, "authorization", configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(inputInviteUser, localVarRequestOptions, configuration);
            return {
                url: (0, common_1.toPathString)(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
exports.AdminUserApiAxiosParamCreator = AdminUserApiAxiosParamCreator;
/**
 * AdminUserApi - functional programming interface
 * @export
 */
const AdminUserApiFp = function (configuration) {
    const localVarAxiosParamCreator = (0, exports.AdminUserApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Generate payment link for user
         * @param {string} planId
         * @param {'month' | 'year'} recurrence
         * @param {string} apiToken
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePaymentLinkForUser(planId, recurrence, apiToken, userId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.generatePaymentLinkForUser(planId, recurrence, apiToken, userId, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Invite user
         * @param {InputInviteUser} [inputInviteUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser(inputInviteUser, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.inviteUser(inputInviteUser, options);
                return (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
exports.AdminUserApiFp = AdminUserApiFp;
/**
 * AdminUserApi - factory interface
 * @export
 */
const AdminUserApiFactory = function (configuration, basePath, axios) {
    const localVarFp = (0, exports.AdminUserApiFp)(configuration);
    return {
        /**
         *
         * @summary Generate payment link for user
         * @param {string} planId
         * @param {'month' | 'year'} recurrence
         * @param {string} apiToken
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePaymentLinkForUser(planId, recurrence, apiToken, userId, options) {
            return localVarFp.generatePaymentLinkForUser(planId, recurrence, apiToken, userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Invite user
         * @param {InputInviteUser} [inputInviteUser]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUser(inputInviteUser, options) {
            return localVarFp.inviteUser(inputInviteUser, options).then((request) => request(axios, basePath));
        },
    };
};
exports.AdminUserApiFactory = AdminUserApiFactory;
/**
 * AdminUserApi - object-oriented interface
 * @export
 * @class AdminUserApi
 * @extends {BaseAPI}
 */
class AdminUserApi extends base_1.BaseAPI {
    /**
     *
     * @summary Generate payment link for user
     * @param {string} planId
     * @param {'month' | 'year'} recurrence
     * @param {string} apiToken
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    generatePaymentLinkForUser(planId, recurrence, apiToken, userId, options) {
        return (0, exports.AdminUserApiFp)(this.configuration).generatePaymentLinkForUser(planId, recurrence, apiToken, userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Invite user
     * @param {InputInviteUser} [inputInviteUser]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserApi
     */
    inviteUser(inputInviteUser, options) {
        return (0, exports.AdminUserApiFp)(this.configuration).inviteUser(inputInviteUser, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AdminUserApi = AdminUserApi;
